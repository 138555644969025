import React from "react";
import { useSelector } from "react-redux";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import { hexToRgb, rgbToStyleString } from "../../services/makeMerchantColor";
import { DEFAULT_COLOR } from "../../App";
import "./style.css";

const FloatingButton = ({
  handleClick,
  bottomPx = '20px',
  children
}) => {
  const merchant = useSelector(selectMerchant)

  const rgbColor = merchant.app && merchant.app.main_color_hex
    ? hexToRgb(merchant.app.main_color_hex)
    : hexToRgb(DEFAULT_COLOR);
  const fadedColor = rgbToStyleString(rgbColor, 1);

  return (
    <div
      className="floating-button"
      onClick={handleClick}
      style={{ background: fadedColor, bottom: bottomPx }}
    >
      {children}
    </div>
  );
};

export default FloatingButton;
