import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  enTranslation,
  ruTranslation,
  trTranslation,
  kaTranslation,
} from "./locales";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

// Initialize i18next with default language 'en'
const defaultLanguage = "en";

export const initLocalization = () => {
  i18n
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
      resources: {
        en: { translation: enTranslation },
        ru: { translation: ruTranslation },
        tr: { translation: trTranslation },
        ka: { translation: kaTranslation },
      },
      fallbackLng: defaultLanguage, // Fallback language
      interpolation: {
        escapeValue: false, // React already escapes by default
      },
      detection: {
        // order and from where user language should be detected
        order: ["localStorage", "navigator"],
        caches: ["localStorage"],
        excludeCacheFor: ["cimode"],
        lookupLocalStorage: "i18nextLng",
      },
    });
};

export function getTextByLang(
  currentUILanguage,
  merchantDefaultLanguage,
  defaultText,
  translateMap
) {
  if (currentUILanguage === merchantDefaultLanguage) {
    return defaultText;
  }

  if (!translateMap) {
    return defaultText;
  }

  const translatedText = translateMap[currentUILanguage];
  if (!translatedText) {
    return defaultText;
  }

  return translatedText;
}
