import React, { memo } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import { analyticsInstance, Source } from "../../services/AnalyticsCollector";

const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const RestaurantInfo = () => {
  const { t } = useTranslation();
  const merchant = useSelector(selectMerchant);

  const closeFunc = () => {
    document.body.style.overflow = "";
    document
      .querySelector(".restaurant-info-container")
      .classList.remove("show");

    analyticsInstance.addToAnalyticsQueue({
      event_label: "close",
      source: Source.MerchantInfo,
    });
  };

  const workTime = merchant.work_time;

  const renderWorkingHours = () => {
    if (!workTime) {
      return null;
    }

    const shortDaysOfWeek = [
      t("common.days_short_name.monday"),
      t("common.days_short_name.tuesday"),
      t("common.days_short_name.wednesday"),
      t("common.days_short_name.thursday"),
      t("common.days_short_name.friday"),
      t("common.days_short_name.saturday"),
      t("common.days_short_name.sunday"),
    ];

    const items = shortDaysOfWeek.map((day, index) => {
      const dayInfo = workTime[daysOfWeek[index]];
      if (dayInfo && dayInfo.is_working) {
        const openTime = formatTime(dayInfo.open);
        const closeTime = formatTime(dayInfo.close);
        return (
          <p key={day} className="working-hours">
            <b>{day}</b>: {openTime} - {closeTime}
          </p>
        );
      }
      return (
        <p key={day} className="working-hours">
          <b>{day}</b>: {t("info.closed")}
        </p>
      );
    });

    return (
      <div className="sub-category-block">
        <h3 className="subcategory-name">{t("info.working_schedule_hours")}</h3>
        {items}
      </div>
    );
  };

  return (
    <div className="restaurant-info-container">
      <div className="restaurant-info_close-button">
        <ArrowBackIosIcon onClick={closeFunc} style={{ cursor: "pointer" }} />
      </div>
      <div className="">
        <h1 className="main-block-info">{merchant.m_name}</h1>
        <div className="restaurant-info-block">
          <h2 className="category-block-name">
            {t("info.more_info_block_header")}
          </h2>
          <div>{renderWorkingHours()}</div>
        </div>
        <div className="restaurant-info-block">
          <h3 className="category-block-name">
            {t("info.location_and_contact_details")}
          </h3>
          <div>
            {merchant.address && merchant.address.raw ? (
              merchant.address.link ? (
                <a
                  className="restaurant-cell restaurant-connection"
                  href={merchant.address.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LocationOnIcon />
                  {merchant.address.raw}
                </a>
              ) : (
                <p className="restaurant-cell">
                  <LocationOnIcon />
                  {merchant.address.raw}
                </p>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const formatTime = (timeObject) => {
  if (timeObject !== undefined) {
    const { hour, minute } = timeObject;
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    return `${formattedHour}:${formattedMinute}`;
  }
};

export default memo(RestaurantInfo);
