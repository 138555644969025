import React, { memo, useEffect, useRef, useState } from "react";

import DefaultImage from "../../default-image.jpeg";
import ProductLoaderButton from "../../Components/ProductLoaderButton";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { clearPopupItem, selectPopupItem } from "../../redux/slices/popupSlice";
import { addItem, selectCartItems } from "../../redux/slices/cartSlice";
import { URL } from "../../services/config";
import { useTranslation } from "react-i18next";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import { getTextByLang } from "../../localization";
import { useSpring, animated } from 'react-spring';
import { EntityName, Source, analyticsInstance} from "../../services/AnalyticsCollector";

const PopupProduct = () => {
  const popup = useSelector(selectPopupItem);

  return <>{popup !== null && <PopupBlock />}</>;
};

function PopupBlock() {
  const popupItem = useSelector(selectPopupItem);
  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const merchant = useSelector(selectMerchant);
  const popupBlockRef = useRef(null);
  const popupSectionRef = useRef(null);
  const [scrollPosition] = useState(window.pageYOffset);
  const [touchCoordinates, setTouchCoordinates] = useState(0);
  const [attachedTop, setAttachedTop] = useState(false);
  const isMobile = window.innerWidth <= 768;

  const isProductInCart = cartItems.some((item) => item.id === popupItem.id);

  const [props, set] = useSpring(() => ({
    marginTop: isMobile ? (attachedTop ? '20px' : '200px') : '100px',
    config: { tension: 300, friction: 30 }
  }));

  useEffect(() => {
    document.body.style.top = -window.pageYOffset + "px";
    document.body.classList.add("no-scroll");
  }, []);

  const updateUserTouchCoordinates = (newValue) => {
    setTouchCoordinates((prevValue) => (newValue > 0 ? newValue : prevValue));
  };

  const userTouchMove = (event) => {
    const pY = Math.round(event.touches[0].clientY);
    updateUserTouchCoordinates(pY);

    set({ marginTop: `${touchCoordinates}px` });
  };

  const userTouchEnd = () => {
    if (touchCoordinates < 120) {
      setAttachedTop(true);
      set({ marginTop: '30px' });
    } else {
      setAttachedTop(false);
      set({ marginTop: '200px' });
    }

    if (touchCoordinates > 300) {
      hidePopupWithAnimo()
    }
  };

  const hidePopup = () => {
    document.body.style = "";
    document.body.classList.remove("no-scroll");

    document.documentElement.style.scrollBehavior = "auto";
    window.scroll(0, scrollPosition);
    document.documentElement.style.scrollBehavior = "";

    dispatch(clearPopupItem());
  };

  const hidePopupWithAnimo = () => {
    if (attachedTop) {
      hidePopup();
    } else {
      const popupBlock = document.querySelector(".popup-product_block");
      popupBlock.style.transform = "translateY(100%)";

      setTimeout(() => {
        hidePopup();
      }, 300);
    }
  };

  const addToCartFunction = () => {
    dispatch(addItem(popupItem));
    analyticsInstance.addToAnalyticsQueue({
      entity_id: popupItem.id.toString(),
      entity_name: EntityName.Item,
      event_label: "item.to_card.added",
      source: Source.ItemModalView,
      custom_fields: {
        price: popupItem.price,
      },
    });
    setTimeout(hidePopupWithAnimo, 700);
  };

  const {
    images = [],
    i_name = "",
    i_name_translate = {},
    description = "",
    description_translate = {},
    price: priceItem = 0,
    food: {
      nutritional: {
        carbohydrates = 0,
        fat: fats = 0,
        per_gram = 0,
        kcal = 0,
        protein: proteins = 0,
        total_gram: weight = 0,
      } = {},
      cooking_time_sec,
    } = {},
  } = popupItem || {};

  const image =
    images.length > 0 ? `${URL}${images[0].original}` : DefaultImage;

  const nutritionalData = [
    { value: kcal, label: "nutritional.kcal" },
    { value: weight, label: "nutritional.weight" },
    { value: proteins, label: "nutritional.proteins" },
    { value: fats, label: "nutritional.fats" },
    { value: carbohydrates, label: "nutritional.carbohydrates" },
  ];

  const validNutritionalData = nutritionalData.filter(item => item.value > 0);

  return (
    <div
      className="popup-section"
      ref={popupSectionRef}
      onClick={(event) =>
        event.target.className === "popup-section" && hidePopupWithAnimo()
      }
    >
      <animated.div
        className="popup-product_block show"
        ref={popupBlockRef}
        style={props}
      >
        <div
          className="popup-block-head"
          onTouchMove={userTouchMove}
          onTouchEnd={userTouchEnd}
        >
          {
            isMobile && (
              <div className="popup-head-line"></div>
            )
          }
        </div>
        <div className="popup-product-info">
          <div
            className="popup-product_image"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <div className="popup-product_content">
            <div className="popup-product_content_header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p className="popup-product_content_name">
                  {getTextByLang(
                    i18n.language,
                    merchant.main_language,
                    i_name,
                    i_name_translate
                  )}
                </p>
                {cooking_time_sec && (
                  <div
                    className="cooking-time-block"
                    style={{ marginTop: "5px", marginLeft: "10px" }}
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 20 20"
                      fill="none"
                      style={{ marginRight: "6px" }}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C17.995 5.58378 14.4162 2.00496 10 2ZM15 11H9V5H11V9H15V11Z"
                        fill="#ACACAC"
                        fillOpacity="0.85"
                      />
                    </svg>
                    {cooking_time_sec / 60} {t("common.time_short_name.minute")}
                  </div>
                )}
              </div>

              <ProductLoaderButton
                price={priceItem}
                classname="popup"
                clickFunction={addToCartFunction}
                isProductInCart={isProductInCart}
              />
            </div>
            <p className="popup-product_content_description">
              {getTextByLang(
                i18n.language,
                merchant.main_language,
                description,
                description_translate
              )}
            </p>
            {
              validNutritionalData.length > 0 && (
                <div className="popup-product-options">
                  <table>
                    <thead>
                      <tr>
                        {validNutritionalData.map((data, index) => (
                          <th key={index}>{data.value}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {validNutritionalData.map((data, index) => (
                          <td key={index}>{t(data.label)}</td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            }
          </div>
        </div>
      </animated.div>
    </div>
  );
}

export default memo(PopupProduct);
