import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menu: []
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload
    }
  },
});

// Экспортируйте actions для использования их в компонентах
export const { setMenu } = menuSlice.actions;

export const selectMenu = (state) => state.menu.menu;

// Экспортируйте reducer для store
export default menuSlice.reducer;
