import React from "react";
import CheckIcon from "@material-ui/icons/Check";

const CheckLoader = () => {
  return (
    <div className="success-checkmark">
      <CheckIcon style={{ color: "whitesmoke" }} />
    </div>
  );
};

export default CheckLoader;
