import React, { useState } from "react";

import SuperEllipse from "react-superellipse";
import { useDispatch } from "react-redux";
import DefaultImage from "../../default-image.jpeg";
import "./style.css";
import { addItem, removeItem } from "../../redux/slices/cartSlice";
import { URL } from "../../services/config";
import {
  EntityName,
  Source,
  analyticsInstance,
} from "../../services/AnalyticsCollector";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import Notification from "../Notification";
import { useSelector } from "react-redux";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import { getTextByLang } from "../../localization";
import { hexToRgb, rgbToStyleString } from "../../services/makeMerchantColor";
import { DEFAULT_COLOR } from "../../App";

const CartProductItem = ({ obj }) => {
  const merchant = useSelector(selectMerchant);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [modalStatus, setModalStatus] = useState(null);
  const { i_name, i_name_translate, count, images, price, food } = obj;

  const imageUrl = images ? `${URL}${images[0].original}` : DefaultImage;
  const cookingTime = (food && food.cooking_time_sec / 60) || 0;
  const priceAmount = price.amount / 100;
  const priceISO = price.iso;
  const rgbColor = merchant.app && merchant.app.main_color_hex
    ? hexToRgb(merchant.app.main_color_hex)
    : hexToRgb(DEFAULT_COLOR);
  const fadedColor = rgbToStyleString(rgbColor, 0.65);

  function removeFromCart() {
    if (obj.count === 1) {
      confirmDelete().then((confirmed) => {
        if (confirmed) {
          analyticsInstance.addToAnalyticsQueue({
            entity_id: obj.id.toString(),
            entity_name: EntityName.Item,
            event_label: "cart.item.decrease",
            source: Source.Cart,
            custom_fields: {
              price: obj.price,
            },
          });
          dispatch(removeItem(obj));
        }
      });
    } else {
      analyticsInstance.addToAnalyticsQueue({
        entity_id: obj.id.toString(),
        entity_name: EntityName.Item,
        event_label: "cart.item.decrease",
        source: Source.Cart,
      });
      dispatch(removeItem(obj));
    }
  }

  function confirmDelete() {
    return new Promise((resolve) => {
      setModalStatus({
        status: "warning",
        message: "confirm.confirm_delete_item",
        confirm: true,
        confirmFunc: () => resolve(true),
      });
    });
  }

  function addToCart() {
    analyticsInstance.addToAnalyticsQueue({
      entity_id: obj.id.toString(),
      entity_name: EntityName.Item,
      event_label: "cart.item.increase",
      source: Source.Cart,
      custom_fields: {
        price: obj.price,
      },
    });
    dispatch(addItem(obj));
  }

  return (
    <li className="product-item cart">
      <SuperEllipse
        p1={8}
        p2={22}
        className="product-item_image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className="product-item-info_block">
        <p className="product-item_name">
          {" "}
          {getTextByLang(
            i18n.language,
            merchant.main_language,
            i_name,
            i_name_translate
          )}{" "}
        </p>
        <div className="product-info-block">
          <p className="product-item_price cart">
            {" "}
            {priceAmount} {getSymbolFromCurrency(priceISO)}{" "}
          </p>

          {cookingTime > 0 && (
            <div className="cooking-time-block">
              <svg
                width="17"
                height="17"
                viewBox="0 0 20 20"
                fill="none"
                style={{ marginRight: "6px" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C17.995 5.58378 14.4162 2.00496 10 2ZM15 11H9V5H11V9H15V11Z"
                  fill="#ACACAC"
                  fillOpacity="0.85"
                />
              </svg>
              {cookingTime} {t("common.time_short_name.minute")}
            </div>
          )}
        </div>
      </div>
      <div className="product-item-info_block second">
        <div className="product-buttons-block">
          <div className="cart-regulation-sum-button">
            <button
              className={
                removeFromCart
                  ? "cart-product-item_button reg-button"
                  : "cart-product-item_button reg-button hidden"
              }
              onClick={removeFromCart}
              style={{ background: fadedColor }}
            >
              <svg
                width="14"
                height="2"
                viewBox="0 0 14 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 2V0H14V2H0Z" fill="white" />
              </svg>
            </button>
            <p className="cart-product_sum-items"> {count} </p>
            <button
              className={
                addToCart
                  ? "cart-product-item_button reg-button"
                  : "cart-product-item_button reg-button hidden"
              }
              onClick={addToCart}
              style={{ background: fadedColor }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 8V14H6V8H0V6H6V0H8V6H14V8H8Z" fill="white" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {modalStatus !== null && (
        <Notification
          result={modalStatus}
          closeModal={() => setModalStatus(null)}
        />
      )}
    </li>
  );
};

export default CartProductItem;
