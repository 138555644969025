import React, { useState } from "react";
import "./style.css";

const SearchComponent = () => {
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  function searchItemFunc(value) {
    const massive = document.querySelectorAll(".category-item");

    massive.forEach((category) => {
      const dishItem = category.querySelectorAll(".product-item");

      category.style.display = "none";

      dishItem.forEach((item) => {
        item.classList.add("none");
        const title = item.querySelector(".product-item_name");

        if (title.innerHTML.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          item.classList.remove("none");
          category.style = null;
        }
      });
    });

    setSearchValue(value);
  }

  function closeSearching() {
    setSearchActive(false);
    searchItemFunc("");
  }

  return (
    <div
      className={searchActive ? "search-container show" : "search-container"}
    >
      <div
        className="search-status-icon-block"
        onClick={() =>
          searchActive ? closeSearching() : setSearchActive(true)
        }
      >
        {searchActive ? (
          <svg
            width="15"
            height="15"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.59 0L5 3.59L1.41 0L0 1.41L3.59 5L0 8.59L1.41 10L5 6.41L8.59 10L10 8.59L6.41 5L10 1.41L8.59 0Z"
              fill="#ACACAC"
            />
          </svg>
        ) : (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.677 16.607L9.962 10.891C7.41967 12.6985 3.91644 12.2564 1.90288 9.87393C-0.110688 7.49152 0.0371623 3.96359 2.243 1.758C4.44826 -0.448549 7.97653 -0.597039 10.3594 1.41642C12.7423 3.42988 13.1846 6.93345 11.377 9.476L17.092 15.192L15.678 16.606L15.677 16.607ZM6.485 1.99999C4.58871 1.99956 2.95269 3.33068 2.56748 5.18744C2.18226 7.0442 3.15371 8.91628 4.89368 9.67024C6.63365 10.4242 8.6639 9.85283 9.75525 8.30206C10.8466 6.75128 10.6991 4.64732 9.402 3.264L10.007 3.864L9.325 3.184L9.313 3.172C8.56479 2.41918 7.5464 1.99713 6.485 1.99999Z"
              fill="#ACACAC"
            />
          </svg>
        )}
      </div>

      <input
        type="text"
        onFocus={() => setSearchActive(true)}
        onChange={(event) => searchItemFunc(event.target.value)}
        value={searchValue}
        className="search-input"
        placeholder="Поиск..."
      />
    </div>
  );
};

export default SearchComponent;
