import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  merchant: {
    m_name: "",
    address: {
      raw: "",
      link: "",
    },
  }
}

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    setMerchant: (state, action) => {
      state.merchant = action.payload
    }
  },
});

// Экспортируйте actions для использования их в компонентах
export const { setMerchant } = merchantSlice.actions;

export const selectMerchant = (state) => state.merchant.merchant;

// Экспортируйте reducer для store
export default merchantSlice.reducer;
