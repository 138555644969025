import React, { useEffect, useState } from "react";
import CheckLoader from "./CheckLoader";

import "./style.css";
import getSymbolFromCurrency from "currency-symbol-map";
import { useSelector } from "react-redux";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import { DEFAULT_COLOR } from "../../App";
import { hexToRgb, rgbToStyleString } from "../../services/makeMerchantColor";

const ProductLoaderButton = ({
  price,
  classname = null,
  clickFunction,
  isProductInCart
}) => {
  const merchant = useSelector(selectMerchant)
  const [loading, setLoading] = useState(false);
  const timer = React.useRef();
  const { amount, iso } = price

  const rgbColor = merchant.app && merchant.app.main_color_hex
    ? hexToRgb(merchant.app.main_color_hex)
    : hexToRgb(DEFAULT_COLOR);
  const fadedColor = rgbToStyleString(rgbColor, 0.65);

  const handleButtonClick = (event) => {
    event.stopPropagation()

    if (!loading) {
      setLoading(true);
      clickFunction();

      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <button
      className={`product-item_button ${isProductInCart && "selected"} ${classname}`}
      style={isProductInCart ? { background: fadedColor } : null}
      onClick={(event) => handleButtonClick(event)}
    >
      {loading ? (
        <CheckLoader />
      ) : (
        <p className="product-item_price">
          {amount / 100} <span className="price-currency">{getSymbolFromCurrency(iso)}</span>
        </p>
      )}
    </button>
  );
};

export default ProductLoaderButton;
