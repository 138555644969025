import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import "./style.css";
import { EntityName, Source, analyticsInstance } from "../../services/AnalyticsCollector";

const languageISO693ToHuman = {
  en: {
    ISO693: "en",
    label: "English",
    country_code: "GB",
  },
  ru: {
    ISO693: "ru",
    label: "Русский",
    country_code: "RU",
  },
  tr: {
    ISO693: "tr",
    label: "Türkçe",
    country_code: "TR",
  },
  ka: {
    ISO693: "ka",
    label: "ქართული",
    country_code: "GE",
  },
};

const getCodesToRender = (merchant) => {
  const languages = [];

  languages.push(languageISO693ToHuman[merchant.main_language]);

  merchant.languages_enabled?.forEach((el) => {
    if (el.isVisible && languageISO693ToHuman[el.language]) {
      languages.push(languageISO693ToHuman[el.language]);
    }
  });

  return languages;
};

const SelectLanguage = ({ stylist }) => {
  const { i18n } = useTranslation();
  const [languageSelectValue, setLanguageSelectValue] = useState(i18n.language);
  const [languageToSelect, setLanguageToSelect] = useState([]);
  const merchant = useSelector(selectMerchant);

  useState(() => {
    const codesToRender = getCodesToRender(merchant);
    setLanguageToSelect(codesToRender);
  }, []);

  // useEffect for set language to merchant.main_language if lang not allowed
  useEffect(() => {
    const isSelctedLanguageWrong =
      languageToSelect.findIndex((el) => el.ISO693 === i18n.language) === -1;
    if (isSelctedLanguageWrong) {
      i18n.changeLanguage(merchant.main_language);
    }
  }, [i18n, languageToSelect, merchant]);

  const changeLanguage = (event) => {
    const { value } = event.target;

    setLanguageSelectValue(value);

    i18n.changeLanguage(value);
    analyticsInstance.addToAnalyticsQueue({
      entity_id: value,
      entity_name: EntityName.Language,
      event_label: "change_language.click",
      source: Source.Header,
    });
  };

  if (languageToSelect.length <= 1) {
    return null;
  }

  return (
    <select
      className="language-select"
      value={`${languageSelectValue}`}
      onChange={(e) => changeLanguage(e)}
      style={stylist}
    >
      {languageToSelect.map((el) => (
        <option key={el.ISO693} value={el.ISO693}>
          <ReactCountryFlag countryCode={el.country_code} /> {el.label}
        </option>
      ))}
    </select>
  );
};

export default SelectLanguage;
