import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch } from "react-redux"
import Loading from "./Components/Loading"
import MerchantNotFound from "./Containers/404"
import Cart from "./Containers/Cart"
import Header from "./Containers/Header"
import PopupProduct from "./Containers/PopupProduct"
import Products from "./Containers/Products"
import RestaurantInfo from "./Containers/RestaurantInfo"
import { initLocalization } from "./localization"
import { initializeFromLocalStorage } from "./redux/slices/cartSlice"
import { setMenu } from "./redux/slices/menuSlice"
import { setMerchant } from "./redux/slices/merchantSlice"
import { Source, analyticsInstance } from "./services/AnalyticsCollector"
import { backendInstance } from "./services/backend"

initLocalization()

export const DEFAULT_COLOR = "#509aff"

function App() {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [merchantMetaMessage, setMerchantMetaMessage] = useState(
    "Ihtys Menu: Revolutionizing the dining experience with advanced menu tools designed to elevate your business and satisfy customers."
  )
  const handleBeforeUnload = async () => {
    await analyticsInstance.cleanupAndSendRemainingData()
  }

  const handleMerchantResponse = (merchant) => {
    if (merchant && !merchant.errors) {
      dispatch(setMerchant(merchant))
      setMerchantMetaMessage(merchant.description ? merchant.description : `${merchant.m_name} online menu`)
    }
  }

  const handleItemsResponse = (items, savedCart) => {
    if (items && !items.errors) {
      const newProducts = groupByCategory(items)

      dispatch(setMenu(newProducts))

      if (savedCart) {
        const updatedCart = updateCartWithItems(items, savedCart)
        if (updatedCart.length > 0) {
          dispatch(initializeFromLocalStorage({ ...savedCart, items: updatedCart }))
        }
      }
    }
  }

  const updateCartWithItems = (items, savedCart) => {
    return items.reduce((acc, item) => {
      const foundCartItem = savedCart.items.find(cartItem => cartItem.id === item.id)

      if (foundCartItem) {
        acc.push({
          ...item,
          count: foundCartItem.count
        })
      }

      return acc
    }, [])
  }

  useEffect(() => {
    const savedCart = localStorage.getItem("cart2") && JSON.parse(localStorage.getItem("cart2"))

    window.addEventListener("beforeunload", handleBeforeUnload)

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const { merchant, items } = await backendInstance.getMerchantAndItems()

        handleMerchantResponse(merchant)
        window.document.title = `${merchant.m_name} | Ihtys Menu`
        handleItemsResponse(items, savedCart)
      } catch (error) {
        console.error("Error fetching data:", error)
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }

    const loaderTimeout = setTimeout(fetchData, 300)

    analyticsInstance.addToAnalyticsQueue({
      event_label: "site.opened",
      source: Source.ItemsListing,
    })

    return () => {
      clearTimeout(loaderTimeout)
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  const loader = (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading />
    </div>
  )

  const appSection = (
    <>
      <Helmet>
        <meta name="description" content={merchantMetaMessage} />
      </Helmet>
      <Header />
      <div className="app-content-container">
        {/* <NavBar /> */}
        <Products />
        <Cart />
        <PopupProduct />
        <RestaurantInfo />
      </div>
    </>
  )

  return (
    <>{isLoading ? loader : isError ? <MerchantNotFound /> : appSection}</>
  )
}

function groupByCategory(products) {
  const result = []
  const otherProducts = []

  products.forEach((product) => {
    if (product.categories && product.categories.length > 0) {
      product.categories.forEach((category) => {
        const existingCategory = result.find(
          (cat) => cat.name === category.c_name
        )
        if (existingCategory) {
          existingCategory.items.push(product)
        } else {
          const newCategory = {
            id: category.id,
            name: category.c_name,
            pos: category.pos,
            name_translate: category.c_name_translate,
            items: [product]
          }

          if (category.serif) {
            newCategory.serif = {
              href: category.c_name,
              s_name: category.serif.s_name,
              s_name_translations: category.serif.s_name_translations
            }
          }

          result.push(newCategory)
        }
      })
    } else {
      otherProducts.push(product)
    }
  })

  if (otherProducts.length > 0) {
    result.push({
      id: 10000,
      name: t("menu.another_category"),
      items: otherProducts,
    })
  }

  result.sort((a, b) => a.pos - b.pos)

  return result
}

export default React.memo(App)
