import { useEffect, useRef } from "react";
import "./style.css";
import { t } from "i18next";

const Notification = ({ result, closeModal }) => {
  const notificationBlockRef = useRef(null);

  const {
    status,
    message,
    autoHideSec = 3000,
    confirm = false,
    confirmFunc,
  } = result;

  function hideNotificationFunc(event) {
    if (event.target.classList.contains("notification-container")) {
      notificationBlockRef.current.style.opacity = "0";
      setTimeout(closeModal, 400);
    }
  }

  function handleConfirm() {
    notificationBlockRef.current.style.opacity = "0";
    setTimeout(closeModal, 200);

    setTimeout(confirmFunc, 500)
  }

  function handleCancel() {
    notificationBlockRef.current.style.opacity = "0";
    setTimeout(closeModal, 400);
  }

  useEffect(() => {
    if (confirm) return;

    let useEffectTimer = setTimeout(() => {
      notificationBlockRef.current.style.opacity = "0";
      setTimeout(closeModal, 400);
    }, autoHideSec);

    return () => {
      clearTimeout(useEffectTimer);
    };
  }, []);

  return (
    <div
      className={`notification-container`}
      onClick={(event) => hideNotificationFunc(event)}
    >
      <div className={`notification-block ${confirm && "confirm"}`} ref={notificationBlockRef}>
        <div className="notification-icon">
          {
            status === "success"
              ? CheckIcon
              : status === "warning"
                ? WarningIcon
                : status === "info"
                  ? InfoIcon
                  : ErrorIcon
          }
        </div>

        <div className="notification-message">{t(message)}</div>

        {
          confirm && (
            <div className="notifications-confirm-block">
              <button className="confirm-button" onClick={handleConfirm}>
                {t("confirm.yes")}
              </button>

              <button className="confirm-button no" onClick={handleCancel}>
                {t("confirm.no")}
              </button>
            </div>
          )
        }
      </div>
    </div>
  );
};

const CheckIcon = (
  <svg
    width="66"
    height="50"
    viewBox="0 0 66 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.4094 5.54683L21.3331 49.6232L0.591309 28.8814L5.76131 23.7114L21.3331 39.2465L60.2394 0.376831L65.4094 5.54683Z"
      fill="#509AFF"
    />
  </svg>
);
const ErrorIcon = (
  <svg
    width="66"
    height="50"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z"
      fill="#ff2d55"
    />
  </svg>
);
const InfoIcon = (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.25 19.25H35.75V24.75H30.25V19.25ZM30.25 30.25H35.75V46.75H30.25V30.25ZM33 5.5C17.82 5.5 5.5 17.82 5.5 33C5.5 48.18 17.82 60.5 33 60.5C48.18 60.5 60.5 48.18 60.5 33C60.5 17.82 48.18 5.5 33 5.5ZM33 55C20.8725 55 11 45.1275 11 33C11 20.8725 20.8725 11 33 11C45.1275 11 55 20.8725 55 33C55 45.1275 45.1275 55 33 55Z"
      fill="#509AFF"
    />
  </svg>
);

const WarningIcon = (
  <svg
    width="66"
    height="50"
    viewBox="0 0 24 24"
    id="warning"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.876,2.672a3.309,3.309,0,0,0-5.752,0L.414,18.19a3.178,3.178,0,0,0,.029,3.189A3.264,3.264,0,0,0,3.29,23H20.71a3.264,3.264,0,0,0,2.847-1.621,3.178,3.178,0,0,0,.029-3.189ZM12,19a1,1,0,1,1,1-1A1,1,0,0,1,12,19Zm1-5a1,1,0,0,1-2,0V8a1,1,0,0,1,2,0Z"
      fill="#FFA500"
    />
  </svg>
)

export default Notification;
