import React, { useState, useEffect, useRef } from "react";
import SearchComponent from "../../Components/SearchComponent";
import { useSelector } from "react-redux";
import { selectMenu } from "../../redux/slices/menuSlice";
import {
  EntityName,
  Source,
  analyticsInstance,
} from "../../services/AnalyticsCollector";
import { getTextByLang } from "../../localization";
import { useTranslation } from "react-i18next";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import "./style.css";

const NewNavbar = ({ categoryRefs }) => {
  const { i18n } = useTranslation();
  const menu = useSelector(selectMenu);
  const merchant = useSelector(selectMerchant);

  const [activeItemName, setActiveItemName] = useState('');
  const navbarItemsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      let lastItem;
      for (const item of menu) {
        const ref = categoryRefs.current[item.name];
        if (ref && ref.getBoundingClientRect().top <= window.innerHeight * 0.2) {
          lastItem = item.name;
        }
      }
      setActiveItemName(lastItem);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menu]);

  const handleClick = (category) => {
    setActiveItemName(category.name);

    analyticsInstance.addToAnalyticsQueue({
      entity_id: category.id.toString(),
      entity_name: EntityName.Category,
      event_label: "category.navigate.click",
      source: Source.ItemsListing,
    });

    const ref = categoryRefs.current[category.name];
    if (ref) {
      const topPosition = ref.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleSerifClick = (category) => {
    analyticsInstance.addToAnalyticsQueue({
      entity_id: category.id.toString(),
      entity_name: EntityName.Category,
      event_label: "serif.navigate.click",
      source: Source.ItemsListing,
    });

    const ref = categoryRefs.current[category.name];
    if (ref) {
      const topPosition = ref.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      });
    }

    const targetElem = navbarItemsRef.current[category.name];
    if (targetElem) {
      targetElem.scrollIntoView({ block: "nearest", inline: "start" });
    }
  };

  const hasSerif = menu.some(item => item.serif);

  return (
    <nav className="nav-categories-container sticky-navbar">
      {hasSerif && (
        <div className="categories-block hide-scrollbar" style={{ marginLeft: '20px', marginBottom: '8px' }}>
          {
            <ul className="navbar-ul">
              {menu.filter(item => item.serif).map(renderSerifItem)}
            </ul>
          }
        </div>
      )}
      <div className="categories-block hide-scrollbar">
        {menu.length > 0 ? (
          <>
            <SearchComponent />
            <ul className="navbar-ul">
              {menu.map(renderMenuItem)}
            </ul>
          </>
        ) : (
          <p className="distance-error-message second-message">
            There are no menu items yet
          </p>
        )}
      </div>
    </nav>
  );

  function renderMenuItem(item) {
    return (
      <NavbarItem
        key={item.id}
        title={getTextByLang(
          i18n.language,
          merchant.main_language,
          item.name,
          item.name_translate
        )}
        onClick={() => handleClick(item)}
        isActive={activeItemName === item.name}
        ref={(el) => (navbarItemsRef.current[item.name] = el)}
      />
    );
  }

  function renderSerifItem(item) {
    if (!item.serif) {
      return null;
    }

    const { s_name, s_name_translations } = item.serif;
    return (
      <NavbarItem
        key={item.id}
        title={getTextByLang(
          i18n.language,
          merchant.main_language,
          s_name,
          s_name_translations
        )}
        onClick={() => handleSerifClick(item)}
        ref={(el) => (navbarItemsRef.current[s_name] = el)}
      />
    );
  }
};

const NavbarItem = React.forwardRef(({ title, onClick, isActive }, ref) => {
  return (
    <li
      className={`navbar_item ${isActive ? "active" : ""}`}
      onClick={onClick}
      ref={ref}
    >
      <p>{title}</p>
    </li>
  );
});

export default NewNavbar;
