import { configureStore } from '@reduxjs/toolkit';
import merchantReducer from './slices/merchantSlice';
import menuReducer from './slices/menuSlice'
import cartReducer from './slices/cartSlice'
import popupReducer from './slices/popupSlice'

function aggsItemsForLocalstorage(items) {
  return items.map(item => {
    return {
      id: item.id,
      count: item.count
    }
  })
}

const cartMiddleware = store => next => action => {
  let result = next(action);

  if (
    action.type === "cart/addItem" ||
    action.type === "cart/removeItem" ||
    action.type === "cart/clearCart"
  ) {
    const newState = store.getState()

    const aggsLocalstorageCartData = {
      itemsCount: newState.cart.itemsCount,
      items: aggsItemsForLocalstorage(newState.cart.items),
      totalCost: newState.cart.totalCost,
    }

    localStorage.setItem("cart2", JSON.stringify(aggsLocalstorageCartData));
  }

  return result;
};

export const store = configureStore({
  reducer: {
    merchant: merchantReducer,
    menu: menuReducer,
    cart: cartReducer,
    popupItem: popupReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(cartMiddleware)
});