import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/store";

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

const root = ReactDOM.createRoot(
  document.getElementById("root"),
);
root.render(app);

serviceWorker.register();
