import React, { useState } from "react"

import { ArrowUpwardRounded } from "@material-ui/icons"
import getSymbolFromCurrency from "currency-symbol-map"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { DEFAULT_COLOR } from "../../App"
import CartProductItem from "../../Components/CartProductItem"
import FloatingButton from "../../Components/FloatingButton"
import Notification from "../../Components/Notification"
import SelectLanguage from "../../Components/SelectLanguage"
import { getTextByLang } from "../../localization"
import { clearCart, selectCartItemCount, selectCartItems, selectCartTotalCost } from "../../redux/slices/cartSlice"
import { selectMerchant } from "../../redux/slices/merchantSlice"
import { Source, analyticsInstance } from "../../services/AnalyticsCollector"
import { hexToRgb, rgbToStyleString } from "../../services/makeMerchantColor"
import "./style.css"

const Cart = () => {
  const cartTotalCost = useSelector(selectCartTotalCost)
  const cartItems = useSelector(selectCartItems)
  const cartTotalCount = useSelector(selectCartItemCount)
  const merchant = useSelector(selectMerchant)
  const dispatch = useDispatch()

  const { t, i18n } = useTranslation()
  const [orderStatusShow, setOrderStatusShow] = useState(false)
  const [modalStatus, setModalStatus] = useState(null)

  let cookingTime = 0
  const rgbColor = merchant.app && merchant.app.main_color_hex
    ? hexToRgb(merchant.app.main_color_hex)
    : hexToRgb(DEFAULT_COLOR)
  const fadedColor = rgbToStyleString(rgbColor, 1)

  function showFullCartFunc() {
    document.querySelector(".full-cart-section").classList.add("show")
    document.body.style.overflow = "hidden"
  }

  function closeFullCartFunc() {
    document.querySelector(".full-cart-section").classList.remove("show")
    document.body.style = null
  }

  function clearCartFunction() {
    if (cartItems.length < 1) {
      closeFullCartFunc()
    } else {
      confirmClearCart()
        .then(confirmed => {
          if (confirmed) {
            closeFullCartFunc()
            dispatch(clearCart())
            analyticsInstance.addToAnalyticsQueue({
              event_label: "clear_all",
              source: Source.Cart,
            })
          }
        })
    }
  }

  function confirmClearCart() {
    return new Promise((resolve) => {
      setModalStatus({
        status: "warning",
        message: "confirm.confirm_clear_cart",
        confirm: true,
        confirmFunc: () => resolve(true),
      })
    })
  }

  const renderCartProductItem = (item) => {
    return (
      <CartProductItem
        key={item.id}
        obj={item}
      />
    )
  }

  const scrollToHigh = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const cartTotalCostByCurrency = cartItems.reduce((acc, item) => {
    acc[item.price.iso] = (acc[item.price.iso] || 0) + item.price.amount * item.count
    return acc
  }, {})

  if (cartTotalCount < 1) {
    orderStatusShow && setOrderStatusShow(false)
  }

  const FullCartComponent = (
    <div className="full-cart_main-block">
      <p className="cart-info">
        {cartItems.length > 0 ? (
          <>
            {t('cart.in_cart_count')}: {cartTotalCount}{" "}
            <br />
          </>
        ) : (
          <>{t('cart.cart_is_empty')}</>
        )}
      </p>

      {cartItems.length > 0 && (
        <ul className="category-list">
          {cartItems.map(renderCartProductItem)}
        </ul>
      )}

      <div>
        {
          merchant.cart_call_to_action && (
            <p className="cart-message">
              {getTextByLang(
                i18n.language,
                merchant.main_language,
                merchant.cart_call_to_action,
                merchant.cart_call_to_action_translate,
              )}
            </p>
          )
        }
        <p className="cart-info" style={{ marginTop: "20px", marginBottom: '20px' }}>
          {
            cartTotalCost ? (
              <>
                {t('cart.total_price')}: <br />
              </>
            ) : (
              null
            )
          }

          {
            Object.entries(cartTotalCostByCurrency).map(([currency, amount]) => (
              <p style={{ fontWeight: 500, fontSize: "20px" }}> {amount / 100} {getSymbolFromCurrency(currency)} </p>
            ))
          }
        </p>

        <span
          className="full-cart_button clear-all-cart_button"
          style={{ marginLeft: '10px' }}
          onClick={clearCartFunction}
        >
          {t('cart.clear_all_button')}
        </span>
      </div>
    </div>
  )

  return (
    <>
      <div className={cartItems.length > 0 ? "cart-block show" : "cart-block"}>
        <div
          className="cart-button"
          onClick={showFullCartFunc}
          style={{ background: fadedColor }}
        >
          {cookingTime > 0 ? (
            <>
              <p>Заказ на {cartTotalCost}&#8381;</p>
              <p className="cart-button_time">~ {cookingTime} мин</p>
            </>
          ) : (
            <>
              <p>{t('items_listing.open_card_button.in_cart_count')}: {cartTotalCount}</p>
              <p style={{ display: "flex" }}>
                {
                  Object.entries(cartTotalCostByCurrency).map(([currency, amount]) => (
                    <p style={{ fontWeight: 500, fontSize: "20px", marginLeft: "10px" }}> {amount / 100} {getSymbolFromCurrency(currency)} </p>
                  ))
                }
              </p>
            </>
          )}
        </div>
      </div>

      <FloatingButton handleClick={scrollToHigh}>
        <ArrowUpwardRounded style={{ color: '#fff', width: '25px' }} />
      </FloatingButton>


      <div className="full-cart-section">
        <div className="full-cart_head-block">
          <svg
            width="33"
            height="33"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={closeFullCartFunc}
            style={{ cursor: 'pointer' }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.8466 10.6534C21.1761 10.9829 21.1761 11.5171 20.8466 11.8466L14.0966 18.5966C13.7671 18.9261 13.2329 18.9261 12.9034 18.5966L6.15338 11.8466C5.82387 11.5171 5.82387 10.9829 6.15338 10.6534C6.48288 10.3239 7.01712 10.3239 7.34662 10.6534L13.5 16.8068L19.6534 10.6534C19.9829 10.3239 20.5171 10.3239 20.8466 10.6534Z"
              fill="#303030"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          {/* <span
            className="full-cart_button clear-all-cart_button"
            onClick={clearCartFunction}
          >
            {t('cart.clear_all_button')}
          </span> */}

          <SelectLanguage stylist={{ marginRight: '0px' }} />
        </div>

        {FullCartComponent}
      </div>

      {modalStatus !== null && (
        <Notification
          result={modalStatus}
          closeModal={() => setModalStatus(null)}
        />
      )}
    </>
  )
}

export default Cart
