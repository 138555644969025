import React, { useEffect } from "react";
import SuperEllipse from "react-superellipse";

import { useDispatch, useSelector } from "react-redux";

import { addItem, selectCartItems } from "../../redux/slices/cartSlice";
import DefaultImage from "../../default-image.jpeg";
import ProductLoaderButton from "../ProductLoaderButton";
import { URL } from "../../services/config";
import "./style.css";
import { setPopupItem } from "../../redux/slices/popupSlice";
import {
  EntityName,
  Source,
  analyticsInstance,
} from "../../services/AnalyticsCollector";
import { useTranslation } from "react-i18next";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import { getTextByLang } from "../../localization";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductItem = ({ productItem }) => {
  const { t, i18n } = useTranslation();
  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();
  const { id, i_name, i_name_translate, images, food } = productItem;
  const merchant = useSelector(selectMerchant);
  const imageUrl = images ? `${URL}${images[0].original}` : DefaultImage;
  const cookingTime = food ? food.cooking_time_sec / 60 : null;
  const isProductInCart = cartItems.some((item) => item.id === productItem.id);

  const showPopup = () => {
    dispatch(setPopupItem(productItem));

    analyticsInstance.addToAnalyticsQueue({
      entity_id: id.toString(),
      entity_name: EntityName.Item,
      event_label: "item.open.view.modal",
      source: Source.ItemsListing,
    });
  };

  const addToCartHandler = () => {
    dispatch(addItem(productItem));

    analyticsInstance.addToAnalyticsQueue({
      entity_id: productItem.id.toString(),
      entity_name: EntityName.Item,
      event_label: "item.to_card.added",
      source: Source.ItemsListing,
      custom_fields: {
        price: productItem.price,
      },
    });
  };

  return (
    <div>
      <li className="product-item" onClick={showPopup}>
        <LazyLoadImage
          src={imageUrl}
          width={144}
          height={144}
          threshold={1500}
          className="product-item_image loader"
          alt={productItem.title}
        />
        <div className="product-item-info_block">
          <p className="product-item_name">
            {getTextByLang(
              i18n.language,
              merchant.main_language,
              i_name,
              i_name_translate
            )}
          </p>
          <div className="product-buttons-block">
            <ProductLoaderButton
              price={productItem.price}
              clickFunction={addToCartHandler}
              isProductInCart={isProductInCart}
            />
            {cookingTime > 0 && (
              <div className="cooking-time-block">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "6px" }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C17.995 5.58378 14.4162 2.00496 10 2ZM15 11H9V5H11V9H15V11Z"
                    fill="#ACACAC"
                    fillOpacity="0.85"
                  />
                </svg>
                {cookingTime} {t("common.time_short_name.minute")}
              </div>
            )}
          </div>
        </div>
      </li>
    </div>
  );
};

export default ProductItem;
