import React, { useEffect } from "react";
import "./style.css";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import { useSelector } from "react-redux";
import { URL } from "../../services/config";
import {
  analyticsInstance,
  Source,
} from "../../services/AnalyticsCollector";
import SelectLanguage from "../../Components/SelectLanguage";

function Header() {
  const merchant = useSelector(selectMerchant);

  const showInfo = () => {
    document.body.style.overflow = "hidden";
    document.querySelector(".restaurant-info-container").classList.add("show");

    analyticsInstance.addToAnalyticsQueue({
      event_label: "merhant.info.open.clicked",
      source: Source.Header,
    });
  };

  return (
    <div
      className="container header-container"
      style={{
        backgroundImage: `url(${URL}${merchant.header_banner_path})`,
        backgroundColor: `rgba(0, 0, 0, ${
          merchant?.app?.header_shadow_background_transparency || 0
        })`,
      }}
    >
      <div className="header-container_info">
        <h1 className="container-info_name">{merchant.m_name}</h1>
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={showInfo}
          style={{ cursor: "pointer" }}
        >
          <path
            d="M14 27.3333C6.63619 27.3333 0.666656 21.3638 0.666656 14C0.666656 6.63619 6.63619 0.666656 14 0.666656C21.3638 0.666656 27.3333 6.63619 27.3333 14C27.3252 21.3604 21.3604 27.3252 14 27.3333ZM3.33332 14.2293C3.39641 20.0976 8.18813 24.8127 14.0567 24.7813C19.9253 24.7496 24.666 19.9833 24.666 14.1147C24.666 8.246 19.9253 3.47969 14.0567 3.44799C8.18813 3.4166 3.39641 8.13167 3.33332 14V14.2293ZM16.6667 20.6667H12.6667V15.3333H11.3333V12.6667H15.3333V18H16.6667V20.6667ZM15.3333 9.99999H12.6667V7.33332H15.3333V9.99999Z"
            fill="#fff"
          />
        </svg>

        <SelectLanguage />
      </div>
    </div>
  );
}

export default Header;
