import React from "react";

// import NoneGeo2 from "./NoneGeo-2.svg";
// import Astronaut from "./Astronaut.svg";
import AstronautPNG from "./astronaut.png";
import "./style.css";


const MerchantNotFound = ({ distance }) => {
	return (
		<div className="distance-error-block">
			{/* <img src={NoneGeo2} className="distance-error-image" alt="none geolocation icon" /> */}
			{/* <p className="distance-info">
				До Сплетен ещё:
				<br />
				<span> {(distance / 1000).toFixed(1)} km</span>
			</p> */}
			<div className="astronaut-block">
				<img src={AstronautPNG} className="distance-error-image astronaut" alt="none geolocation icon" />

				<div className="astronaut-shadow"></div>
			</div>

			<p className="distance-error-message main-message">
				Oops!
			</p>
			<p className="distance-error-message second-message">
				It seems you're lost
			</p>
			<p className="distance-error-message third-message">
				The page you're looking for doesn't exist
			</p>

			{/* <p>Ваше расстояние до сплетен составляет: {distance}м </p> */}
		</div>
	)
}

export default MerchantNotFound;