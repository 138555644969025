import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  totalCost: 0,
  itemsCount: 0
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {

    addItem: (state, action) => {
      const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
      if (itemIndex > -1) {
        state.items[itemIndex].count += 1;
      } else {
        state.items.push({ ...action.payload, count: 1 });
      }
      state.itemsCount += 1;
      state.totalCost += action.payload.price.amount / 100;
    },

    removeItem: (state, action) => {
      const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
      if (itemIndex > -1) {
        if (state.items[itemIndex].count > 1) {
          state.items[itemIndex].count -= 1;
          state.itemsCount -= 1;
          state.totalCost -= state.items[itemIndex].price.amount / 100;
        } else {
          state.itemsCount -= state.items[itemIndex].count;
          state.totalCost -= (state.items[itemIndex].count * state.items[itemIndex].price.amount) / 100;
          state.items.splice(itemIndex, 1);
        }
      }
    },

    clearCart: (state) => {
      state.items = [];
      state.totalCost = 0;
      state.itemsCount = 0;
    },

    initializeFromLocalStorage: (state, action) => {
      state.items = action.payload.items;
      state.totalCost = action.payload.totalCost;
      state.itemsCount = action.payload.itemsCount;
    },
  },
});

export const { addItem, removeItem, clearCart, initializeFromLocalStorage } = cartSlice.actions;

export const selectCartItems = (state) => state.cart.items;
export const selectCartTotalCost = (state) => state.cart.totalCost;
export const selectCartItemCount = (state) => state.cart.itemsCount;

export default cartSlice.reducer;
