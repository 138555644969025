const isProduction = process.env.NODE_ENV === "production"
const BACKEND_URL = `https://api.ihtys.menu`
const LOCAL_BACKEND_URL = `https://api.ihtys.menu`
// ДАНЯ ХОУМ
// const LOCAL_BACKEND_URL = `http://192.168.1.63:52617`;
const URL = isProduction ? BACKEND_URL : LOCAL_BACKEND_URL
const API_URL = `${URL}/api`

export { API_URL, BACKEND_URL, URL }

