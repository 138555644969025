import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  item: null  // Здесь будет храниться товар, который вы хотите показать в popup
};

export const popupItemSlice = createSlice({
  name: 'popupItem',
  initialState,
  reducers: {
    // Добавление товара для показа в popup
    setPopupItem: (state, action) => {
      state.item = action.payload;
    },
    // Удаление товара из popup
    clearPopupItem: (state) => {
      state.item = null;
    }
  },
});

// Экспортируйте actions для использования их в компонентах
export const { setPopupItem, clearPopupItem } = popupItemSlice.actions;

// Селекторы
export const selectPopupItem = (state) => state.popupItem.item;

// Экспортируйте reducer для store
export default popupItemSlice.reducer;
