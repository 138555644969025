import React, { useRef } from "react";
import "./style.css";
import ProductItem from "../../Components/ProductItem";
import { useSelector } from "react-redux";
import { selectMenu } from "../../redux/slices/menuSlice";
import { selectMerchant } from "../../redux/slices/merchantSlice";
import { getTextByLang } from "../../localization";
import { useTranslation } from "react-i18next";
import NewNavbar from "../NewNavbar";

const Products = () => {
  const menu = useSelector(selectMenu);
  const merchant = useSelector(selectMerchant)
  const { i18n } = useTranslation();

  const categoryRefs = useRef({});

  const renderProductsCategory = (category) => {
    return (
      <div
        className="category-item"
        id={category.name}
        key={category.name}
        ref={ref => categoryRefs.current[category.name] = ref}
      >
        <h2 className="category-name"> {getTextByLang(
          i18n.language,
          merchant.main_language,
          category.name,
          category.name_translate
        )} </h2>
        <ul className="category-list">
          {category.items.map(item => (
            <ProductItem key={item.id} productItem={item} />
          ))}
        </ul>
      </div>
    );
  };

  return (
    <section className="menu-container">
      <div className="categories-container">
        <NewNavbar categoryRefs={categoryRefs} />
        {menu.length > 0 && (
          <>
            {menu.map(renderProductsCategory)}
          </>
        )}
      </div>
    </section>
  );
};

export default Products;
